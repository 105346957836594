import React from "react";
import useStore from "../../../../context/useStore";
import colors from "../../../../helpers/colors";
import tools from "../../../../helpers/tools";
import Accordion from "../../../common/Accordion/Accordion";
import SaveBtn from "../../../common/SaveBtn";
import usePositiveOutcome from "./usePositiveOutcome";

const AccordionTitle = ({ entity, className }) => {
  return (
    <div
      className={`d-flex w-100 align-items-center justify-content-between p-2 rounded ${className} `}
    >
      {entity.name} <span className="badge badge-success">{entity.count}</span>
    </div>
  );
};

const PositiveOutcome = ({ url, year }) => {
  const [state] = useStore();
  const { RDV_EXIT_THEMES, RDV_EXIT_PRO_RESULTS, RDV_EXIT_SOCIAL_RESULTS } =
    state.constants.items;
  const { datas, isLoading, getDatas } = usePositiveOutcome({ url, year });

  const getTotalExit = (data) => {
    if (Object.values(data).length == 0) return 0;
    return Object.values(data).reduce((a, b) => a + b);
  };

  const totalByTheme = {};
  if (datas) {
    const populatedExitProResults = RDV_EXIT_PRO_RESULTS.map((exit) => ({
      ...exit,
      count: datas.RDV_EXIT_PRO_RESULTS[exit.id] || 0,
    }));
    const populatedExitSocialResults = RDV_EXIT_SOCIAL_RESULTS.map((exit) => ({
      ...exit,
      count: datas.RDV_EXIT_SOCIAL_RESULTS[exit.id] || 0,
    }));
    const populatedExits = [
      ...populatedExitProResults,
      ...populatedExitSocialResults,
    ];
    RDV_EXIT_THEMES.forEach((theme) => {
      totalByTheme[theme.id] = 0;
      totalByTheme[theme.id] += populatedExits
        .filter((exit) => exit.theme == theme.id)
        .reduce((a, b) => a + b.count, 0);
    });
  }

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={datas ? "btn-default d-center" : "btn-primary d-center"}
          color={datas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            datas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>

      {!isLoading && datas && (
        <div className="col-12 mt-3">
          <Accordion
            id="statAccordionExitRes"
            k={1}
            className="w-100"
            items={[
              {
                title: (
                  <AccordionTitle
                    entity={{
                      name: "Motifs professionnels",
                      count: getTotalExit(datas.RDV_EXIT_PRO_RESULTS),
                    }}
                  />
                ),
                content: (
                  <ul className="list-group">
                    {RDV_EXIT_PRO_RESULTS.sort((a, b) =>
                      a.theme > b.theme ? 1 : -1
                    ).map((result, gk) => {
                      const count = datas.RDV_EXIT_PRO_RESULTS[result.id] || 0;
                      const theme = tools.findIn(
                        RDV_EXIT_THEMES,
                        "id",
                        result.theme
                      );
                      const isNewTheme =
                        gk > 0
                          ? result.theme !== RDV_EXIT_PRO_RESULTS[gk - 1].theme
                          : true;
                      const color = count > 0 ? "primary" : "default";
                      return (
                        <>
                          {isNewTheme && (
                            <li className=" px-4 font-weight-bold my-1  d-flex align-items-center justify-content-between">
                              {theme.name}{" "}
                              <div>
                                <div className={`badge badge-success`}>
                                  {totalByTheme[result.theme]}
                                </div>
                              </div>
                            </li>
                          )}
                          <li className="list-group-item d-flex align-items-center justify-content-between">
                            <div>{result.name}</div>
                            <div className={`badge badge-${color}`}>
                              {count}
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                ),
              },
            ]}
          />
          <Accordion
            id="statAccordionExitRes"
            k={1}
            className="w-100"
            items={[
              {
                title: (
                  <AccordionTitle
                    entity={{
                      name: "Motifs sociaux",
                      count: getTotalExit(datas.RDV_EXIT_SOCIAL_RESULTS),
                    }}
                  />
                ),
                content: (
                  <ul className="list-group">
                    {RDV_EXIT_SOCIAL_RESULTS.sort((a, b) =>
                      a.theme > b.theme ? 1 : -1
                    ).map((result, gk) => {
                      const count =
                        datas.RDV_EXIT_SOCIAL_RESULTS[result.id] || 0;
                      const color = count > 0 ? "primary" : "default";
                      const theme = tools.findIn(
                        RDV_EXIT_THEMES,
                        "id",
                        result.theme
                      );
                      const isNewTheme =
                        gk > 0
                          ? result.theme !==
                            RDV_EXIT_SOCIAL_RESULTS[gk - 1].theme
                          : true;
                      return (
                        <>
                          {isNewTheme && (
                            <li className="px-4 font-weight-bold my-1 d-flex align-items-center justify-content-between">
                              {theme.name}{" "}
                              <div>
                                <div className={`badge badge-success`}>
                                  {totalByTheme[result.theme]}
                                </div>
                              </div>
                            </li>
                          )}
                          <li className="list-group-item d-flex align-items-center justify-content-between">
                            <div>{result.name}</div>
                            <div className={`badge badge-${color}`}>
                              {count}
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default PositiveOutcome;
