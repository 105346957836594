import React from "react";
import ReactApexChart from "react-apexcharts";
import SaveBtn from "../../../common/SaveBtn";
import colors from "../../../../helpers/colors";
import useNbOrientByTerritory from "./useNbOrientByTerritory";
import Control from "../../../common/Control";

const NbOrientByTerritory = ({
  url,
  year,
  displayTotal,
  showLine = true,
  showPie = true,
  otherPostParams = {},
}) => {
  const {
    isLoading,
    chartState,
    getDatas,
    hasDatas,
    total,
    totalCommune,
    allDatas,
    selectedCommune,
    onClickCommune,
  } = useNbOrientByTerritory(url, year, otherPostParams);

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          color={hasDatas ? colors.primary : colors.default}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync mr-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt mr-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas ? (
        <>
          <div className="col-12">
            <h5>Détail par territoire</h5>
          </div>
          <div className="col-12 col-md-6">
            {showLine && (
              <ReactApexChart
                options={chartState.line1.options}
                series={chartState.line1.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className="col-12 col-md-6">
            {showPie && (
              <ReactApexChart
                options={chartState.pie1.options}
                series={chartState.pie1.series}
                type="pie"
                height={350}
              />
            )}
          </div>
          {displayTotal ? (
            <div className="col-12 text-center font-weight-bold mb-3">
              TOTAL : {total}
            </div>
          ) : null}

          <div className="col-12 mt-3">
            <h5>Détail par commune</h5>
            <div className="row">
              {chartState.line1.series.map((s, i) => {
                const communeDatas = allDatas.filter((m) => m.zone == s.name);
                return (
                  <div className="col-12 col-md-3 mt-2" key={`zone${s.name}`}>
                    <strong className="pl-3">{s.name}</strong>
                    <Control
                      type="btnList"
                      label=""
                      datas={communeDatas.map((m) => ({
                        label: `${m.name} (${m.months.reduce(
                          (a, b) => a + b
                        )})`,
                        id: m.name,
                      }))}
                      btnInline
                      value={selectedCommune}
                      change={onClickCommune}
                      dataIndex="id"
                      dataLabel="label"
                      name="selectedCommune"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12 mt-3">
            {showLine && (
              <ReactApexChart
                options={chartState.line2.options}
                series={chartState.line2.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          {displayTotal ? (
            <div className="col-12 text-center font-weight-bold mb-3">
              TOTAL COMMUNE : {totalCommune}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export default NbOrientByTerritory;
