import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Control from "../../common/Control";
import AnswerInterpretor from "../../common/FormGenerator/AnswerInterpretor";
import Page from "../../common/layout/Page";
import PostalCode from "../../common/PostalCode";
import SaveBtn from "../../common/SaveBtn";
import useApiDatas from "../../Hooks/useApiDatas";

const Simulator = ({
  recipient = false,
  rdsp = false,
  successCallback = () => {},
}) => {
  const {
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    isInit,
    itemId,
    isEdit,
    item,
    otherDatas,
  } = useApiDatas({
    dependenciesPath: ["rdsp", "organizations/orientation_partners"],
  });
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const [answers, setAnswers] = useState(rdsp ? rdsp.rdsp : false);
  const [disabledQuestions, setDisabledQuestions] = useState([]);
  const form = otherDatas.rdsp;
  const organizations = otherDatas["organizations/orientation_partners"];
  const [birthdate, setBirthDate] = useState(
    recipient ? recipient.birthdate : ""
  );
  const [rights, setRights] = useState(
    recipient && recipient.rights && recipient.rights.length
      ? [{ type: recipient.rights[recipient.rights.length - 1].type }]
      : [{ type: "RSD" }]
  );
  const [orientation, setOrientation] = useState(false);
  const [canSimulate, setCanSimulate] = useState({
    result: true,
    reason: [],
  });
  const [location, setLocation] = useState(
    recipient
      ? recipient.location
      : {
          cityId: "",

          postalCode: "",
          postalCodeTown: "",
        }
  );
  const [overridePartner, setOverridePartner] = useState("");

  useEffect(() => {
    if (form && !answers) {
      var ans = [];
      form.questions.forEach((q) => {
        var value = q.type == 7 || q.type == 13 ? [] : "";
        if (q.type == 13) {
          //if the question is a multiple choice question, we need to set the default value to the last answer of each group
          var refAns = q.answers;
          var groups = refAns.map((a) => a.group);
          groups = [...new Set(groups)];
          groups.forEach((g) => {
            var groupAnswers = refAns.filter((a) => a.group == g);
            value.push(groupAnswers[groupAnswers.length - 1].value);
          });
        }
        ans.push({
          question: q.id,
          value: value,
        });
      });

      setAnswers(ans);
    }
  }, [form]);

  useEffect(() => {
    var disabled = [];
    if (form && answers) {
      form.questions.forEach((ques, quesk) => {
        var cond = ques.condition;
        if (cond != "") {
          cond = cond.split("&&");
          cond.forEach((c) => {
            var isCondNotFullfiled = true;
            if (c.includes("in")) {
              var obj = c.split("in");
              var targetValue = obj[1];
              var qsId = parseInt(obj[0].split("q")[1]);
              var targetQuestion = form.questions.find((q) => q.id == qsId).id;
              isCondNotFullfiled = !answers
                .find((a) => a.question == targetQuestion)
                .value.includes(parseInt(targetValue));
            } else {
              var obj = c.split("==");
              var targetValue = obj[1];
              var qsId = parseInt(obj[0].split("q")[1]);
              var targetQuestion = form.questions.find((q) => q.id == qsId).id;
              isCondNotFullfiled =
                answers.find((a) => a.question == targetQuestion).value !=
                targetValue;
            }

            if (isCondNotFullfiled) {
              disabled.push(ques.id);
            }
          });
        }
      });
      setDisabledQuestions(disabled);
    }
  }, [answers, form]);

  function updateAnswer(e) {
    setOrientation(false);
    var ans = JSON.parse(JSON.stringify(answers));
    var elem = ans.find((a) => a.question == e.target.name).value;
    var value = e.target.value;
    var refQues = form.questions.find((q) => q.id == e.target.name);
    if (refQues.type == 13) {
      console.log("refQues", refQues);
      //in this question type, each answer has a "group" property, only 1 answer can be selected per group
      //but several answers can be selected accross groups
      var refAns = refQues.answers;
      var group = refAns.find((a) => a.value == value).group;
      var groupAnswers = refAns.filter((a) => a.group == group);
      var groupValues = groupAnswers.map((a) => a.value);
      elem = elem.filter((v) => !groupValues.includes(v));
      elem.push(value);
    } else if (e.target.formArray) {
      var refAns = refQues.answers;
      //var refAns = form.questions[step].answers;
      var hasExclusive = refAns.filter((a) => a.isExclusive);
      if (e.target.formArray.target.checked) {
        if (e.target.isExclusive) {
          elem = [value];
        } else {
          elem.push(value);
          hasExclusive.forEach((ae) => {
            elem = elem.filter((v) => v != ae.value);
          });
        }
      } else {
        elem = elem.filter((v) => v != value);
      }
    } else {
      elem = value;
    }
    ans.find((a) => a.question == e.target.name).value = elem;
    setAnswers(ans);
  }

  function getOrientation() {
    setIsLoading(true);
    dataService.post(
      "rdsp/simulation",
      {
        rdsp: answers,
        userRecipient: {
          birthdate,
          rights,
          location,
        },
      },
      (data) => setOrientation(data.orientation),
      setErrors,
      () => setIsLoading(false)
    );
  }

  function saveRecipientRdsp() {
    setIsLoading(true);
    if (recipient && !rdsp) {
      dataService.post(
        "recipients/" + recipient.id + "/rdsp",
        {
          user: recipient.id,
          rdsp: answers,
          overridePartner,
        },
        (datas) => {
          modal_actions.updateModal({
            isOpen: false,
            content: null,
          });
          toast.success("RDSP créé avec succès");
          successCallback();
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
    if (recipient && rdsp) {
      dataService.patch(
        "rdsp/" + rdsp.id,
        {
          user: recipient.id,
          rdsp: answers,
        },
        (datas) => {
          toast.success("RDSP modifié avec succès");
          modal_actions.updateModal({
            isOpen: false,
            content: null,
          });
          successCallback();
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  }

  useEffect(() => {
    //sur rights chechek que la dernière endat > today
    //sur rightsAndDuties, check que beginAt < today < endAt
    if (recipient) setCanSimulate(tools.checkCanSimulate(recipient));
  }, [recipient]);

  return !canSimulate.result ? (
    <div className="d-center h-100 w-100">
      <div className="alert alert-danger">
        Vous ne pouvez pas {rdsp ? "modifier" : "créer"} le RDSP de cet
        allocataire pour les raisons suivantes :{" "}
        <ul className="list-group">
          {canSimulate.reason.map((res, resk) => (
            <li className="list-group-item" key={"reason" + res}>
              {res}
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : (
    <Page
      container={"container-fluid px-5"}
      title={
        recipient && !rdsp
          ? "Créer un RDSP pour l'allocataire " +
            recipient.lastname +
            " " +
            recipient.firstname
          : rdsp
          ? "Modifier le RDSP pour l'allocataire " +
            recipient.lastname +
            " " +
            recipient.firstname
          : "Simulateur d'orientation RDSP"
      }
      errors={errors}
    >
      {isInit && answers && (
        <>
          <Control
            type="date"
            label="Date de naissance de l'allocataire"
            change={(e) => setBirthDate(e.target.value)}
            name="birthdate"
            value={DateTime.fromISO(birthdate).toFormat("yyyy-MM-dd")}
            error={errors}
            disabled={recipient}
          />
          <Control
            type="select"
            label="Type de RSA"
            change={(e) => setRights([{ type: e.target.value }])}
            datas={constants.RSA_TYPES}
            name="rights"
            value={rights[0].type}
            error={errors}
            disabled={recipient}
          />
          <PostalCode
            inputLabel="Code postal"
            value={location.cityId}
            updateData={(e) => {
              setLocation({
                ...location,
                cityId: e.target.value,
                postalCode: e.target.other.postalCode,
                postalCodeTown: e.target.other.postalCodeTown,
              });
            }}
            location={location}
            error={errors}
            disabled={recipient}
          />
          <hr />
          {form.questions.map((q, qk) => {
            let isDisabled = disabledQuestions.includes(q.id);
            return (
              <div
                key={"q" + qk}
                style={{
                  width: "100%",
                  left: 0,
                  opacity: isDisabled ? "0.3" : "1",
                }}
                className="position-relative mt-4"
              >
                {isDisabled && (
                  <div
                    className="d-center"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: "99",
                    }}
                  >
                    <button className="btn btn-default bg-white">
                      NON APPLICABLE
                    </button>
                  </div>
                )}
                <div className="question_header">
                  <h4 className="question_title px-2">{q.question}</h4>
                  <p className="question_description mb-0 px-2">
                    {q.description}
                  </p>
                  <div className="question_answer_container mt-0">
                    <AnswerInterpretor
                      question={q}
                      k={qk}
                      inputTypes={constants.INPUT_TYPES}
                      updateAnswer={updateAnswer}
                      answers={answers}
                      errors={errors}
                    />
                    {q.attribute == "email" && (
                      <>
                        <p>
                          Vous ne possédez pas encore de boite email ? Utilisez
                          un de ces liens pour en créer une :
                        </p>
                        <ul>
                          <li>
                            <a target="_BLANK" href="/creation-email.pdf">
                              Créer une boite email sur LA POSTE
                            </a>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}

      <div
        className=" w-100 d-center flex-column"
        style={{ bottom: 10, left: 0 }}
      >
        {orientation && (
          <div className="alert alert-primary mb-0 d-center flex-column animated fadeInUp">
            <div className="d-center">
              Orientation calculée <i className="fa fa-arrow-right mx-2" />{" "}
              {tools.findIn(organizations, "slugName", orientation).name}
            </div>
            {recipient && (
              <div data-priv="create_simulator_rdsp">
                <button className="btn btn-primary" onClick={saveRecipientRdsp}>
                  {rdsp
                    ? "Enregistrer les modifications de ce RDSP"
                    : "Ajouter ce RDSP à l'allocataire et l'orienter vers " +
                      tools.findIn(organizations, "slugName", orientation).name}
                </button>
                <p className="text-center mb-3 mt-4">
                  <i>OU</i>
                </p>

                <Control
                  label="Choisissez une orientation différente"
                  type="select"
                  value={overridePartner}
                  datas={organizations.filter((o) => o.slugName != orientation)}
                  dataIndex="id"
                  dataLabel="name"
                  change={(e) => setOverridePartner(e.target.value)}
                />
                {overridePartner && (
                  <button
                    className="btn btn-primary"
                    onClick={saveRecipientRdsp}
                  >
                    Ajouter ce RDSP à l'allocataire et l'orienter vers{" "}
                    {tools.findIn(organizations, "id", overridePartner).name}
                  </button>
                )}
              </div>
            )}
          </div>
        )}

        <SaveBtn
          save={getOrientation}
          isSaving={isLoading}
          className="d-center"
          margin="mt-2"
          style={{ height: 45 }}
          text={
            <>
              <i className="fa fa-calculator mr-2" />
              Calculer l'orientation
            </>
          }
        />
      </div>
    </Page>
  );
};

export default Simulator;
