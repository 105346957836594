import React from "react";
import useStoreState from "../../../Context/Store/useStoreState";
import AllocAvis from "./AllocAvis";
import AllocDecision from "./AllocDecision";
import AllocInfos from "./AllocInfos";

const EditAlloc = () => {
  const { items_actions } = useStoreState();

  return (
    <div>
      <button
        className="btn btn-primary mb-2"
        onClick={items_actions.resetCurrentAlloc}
      >
        <i className="fa fa-arrow-left mr-2" />
        Retour
      </button>
      <AllocInfos />

      <div className="row mt-3">
        <div className="col-12 col-md-4">
          <AllocAvis />
        </div>
        <div className="col-12 col-md-8">
          <AllocDecision />
        </div>
      </div>
    </div>
  );
};

export default EditAlloc;
