import React from "react";
import useStoreState from "../../../Context/Store/useStoreState";
import AvisReo from "./AvisReo";
import AvisSanction from "./AvisSanction";

const AllocAvis = () => {
  const { items } = useStoreState();
  const recipient = items.utils.currentAlloc;

  const hasBeenASurseoirBefore = () => {
    if (recipient.oldEpDetails.length > 0) {
      return (
        recipient.oldEpDetails.sort(
          (a, b) => (a.EP.beginAt > b ? 1 : -1),
          "2000-01-01"
        )[0].directorNotice.decision == 2
      );
    }
    return false;
  };

  return (
    <div className="custom-card">
      {hasBeenASurseoirBefore() ? (
        <div className="text-warning font-weight-bold">
          <i className="fa fa-exclamation-triangle mr-1" />
          nouveau passage en commission : décision initiale à surseoir
        </div>
      ) : null}
      <strong className="text-primary">Avis de l'organisme</strong>
      {recipient.type == "SANCTION" ? <AvisSanction /> : <AvisReo />}
    </div>
  );
};

export default AllocAvis;
