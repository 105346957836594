import { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useCERTotalCount = (url, year, otherPostParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [total, setTotal] = useState(0);
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const lineOptions = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: months,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year, ...otherPostParams },
      (datas) => {
        var allTotals = [datas.months.reduce((a, b) => a + b)];

        setTotal(allTotals.reduce((a, b) => a + b));
        setChartState({
          line: {
            series: [
              {
                name: datas.name,
                data: datas.months,
              },
            ],
            options: lineOptions,
          },
        });
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
  };
};

export default useCERTotalCount;
