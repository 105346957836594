import React, { useState } from "react";
import dataService from "../../../../helpers/dataService";

const useNbRdvEGCafByTerritory = (url, year, otherPostParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useState(false);
  const [totalHonore, setTotalHonore] = useState(0);
  const [totalNonHonore, setTotalNonHonore] = useState(0);
  const [totalAMener, setTotalAMener] = useState(0);
  const [totalCommuneHonore, setTotalCommuneHonore] = useState(0);
  const [totalCommuneNonHonore, setTotalCommuneNonHonore] = useState(0);
  const [totalCommuneAMener, setTotalCommuneAMener] = useState(0);
  const [selectedCommune, setSelectedCommune] = useState("");
  const [allDatas, setAllDatas] = useState([]);
  const [zoneDatas, setZoneDatas] = useState([]);
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const lineOptions = {
    chart: {
      height: 350,
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: months,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const getDatas = () => {
    // transformDatas([
    //   {
    //     name: "Saint Paul",
    //     zone: "OUEST",
    //     rdvHonoreMonths: getRandomArrayOfSize(12),
    //     rdvNonHonoreMonths: getRandomArrayOfSize(12),
    //     rdvAMenerMonths: getRandomArrayOfSize(12),
    //   },
    //   {
    //     name: "Le Port",
    //     zone: "OUEST",
    //     rdvHonoreMonths: getRandomArrayOfSize(12),
    //     rdvNonHonoreMonths: getRandomArrayOfSize(12),
    //     rdvAMenerMonths: getRandomArrayOfSize(12),
    //   },
    //   {
    //     name: "Saint Denis",
    //     zone: "NORD",
    //     rdvHonoreMonths: getRandomArrayOfSize(12),
    //     rdvNonHonoreMonths: getRandomArrayOfSize(12),
    //     rdvAMenerMonths: getRandomArrayOfSize(12),
    //   },
    //   {
    //     name: "Sainte Marie",
    //     zone: "NORD",
    //     rdvHonoreMonths: getRandomArrayOfSize(12),
    //     rdvNonHonoreMonths: getRandomArrayOfSize(12),
    //     rdvAMenerMonths: getRandomArrayOfSize(12),
    //   },
    //   {
    //     name: "Saint Pierre",
    //     zone: "SUD",
    //     rdvHonoreMonths: getRandomArrayOfSize(12),
    //     rdvNonHonoreMonths: getRandomArrayOfSize(12),
    //     rdvAMenerMonths: getRandomArrayOfSize(12),
    //   },
    //   {
    //     name: "Le Tampon",
    //     zone: "SUD",
    //     rdvHonoreMonths: getRandomArrayOfSize(12),
    //     rdvNonHonoreMonths: getRandomArrayOfSize(12),
    //     rdvAMenerMonths: getRandomArrayOfSize(12),
    //   },
    //   {
    //     name: "Sainte Rose",
    //     zone: "EST",
    //     rdvHonoreMonths: getRandomArrayOfSize(12),
    //     rdvNonHonoreMonths: getRandomArrayOfSize(12),
    //     rdvAMenerMonths: getRandomArrayOfSize(12),
    //   },
    // ]);
    setIsLoading(true);

    dataService.post(
      `${url}`,
      { year, ...otherPostParams },
      (data) => {
        transformDatas(data);
      },
      (e) => {
        console.log(e);
      },
      () => setIsLoading(false)
    );
  };

  const onClickCommune = (e) => {
    setSelectedCommune(e.target.value);
    const communeData = allDatas.filter((o) => o.name == e.target.value);
    setChartState({
      ...chartState,
      line4: {
        series: communeData.map((o) => ({
          name: o.name,
          data: o.rdvHonoreMonths,
        })),
        options: lineOptions,
      },
      pie4: {
        series: communeData.map((o) =>
          o.rdvHonoreMonths.reduce((a, b) => a + b)
        ),
        options: {
          labels: communeData.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      line5: {
        series: communeData.map((o) => ({
          name: o.name,
          data: o.rdvNonHonoreMonths,
        })),
        options: lineOptions,
      },
      pie5: {
        series: communeData.map((o) =>
          o.rdvNonHonoreMonths.reduce((a, b) => a + b)
        ),
        options: {
          labels: communeData.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      line6: {
        series: communeData.map((o) => ({
          name: o.name,
          data: o.rdvAMenerMonths,
        })),
        options: lineOptions,
      },
      pie6: {
        series: communeData.map((o) =>
          o.rdvAMenerMonths.reduce((a, b) => a + b)
        ),
        options: {
          labels: communeData.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
    });
    setTotalCommuneHonore(
      communeData[0].rdvHonoreMonths.reduce((a, b) => a + b)
    );
    setTotalCommuneNonHonore(
      communeData[0].rdvNonHonoreMonths.reduce((a, b) => a + b)
    );
    setTotalCommuneAMener(
      communeData[0].rdvAMenerMonths.reduce((a, b) => a + b)
    );
  };

  const getRandomArrayOfSize = (size) => {
    return Array.from({ length: size }, () => Math.round(Math.random() * 100));
  };

  const transformDatas = (datas) => {
    setAllDatas(datas);
    setSelectedCommune(datas[0].name);

    //make an array of objects called zoneDatas using the datas array, zoneDatas must have the total of each months for each zone
    var rdvHonoreZoneDatas = datas.reduce((acc, curr) => {
      var found = acc.find((o) => o.zone === curr.zone);
      if (found) {
        found.months = found.months.map((o, i) => o + curr.rdvHonoreMonths[i]);
      } else {
        acc.push({ ...curr, name: curr.zone, months: curr.rdvHonoreMonths });
      }
      return acc;
    }, []);
    var rdvNonHonoreZoneDatas = datas.reduce((acc, curr) => {
      var found = acc.find((o) => o.zone === curr.zone);
      if (found) {
        found.months = found.months.map(
          (o, i) => o + curr.rdvNonHonoreMonths[i]
        );
      } else {
        acc.push({ ...curr, name: curr.zone, months: curr.rdvNonHonoreMonths });
      }
      return acc;
    }, []);
    var rdvAMenerZoneDatas = datas.reduce((acc, curr) => {
      var found = acc.find((o) => o.zone === curr.zone);
      if (found) {
        found.months = found.months.map((o, i) => o + curr.rdvAMenerMonths[i]);
      } else {
        acc.push({ ...curr, name: curr.zone, months: curr.rdvAMenerMonths });
      }
      return acc;
    }, []);

    var allHonoreTotals = datas.map((o) =>
      o.rdvHonoreMonths.reduce((a, b) => a + b)
    );
    var allNonHonoreTotals = datas.map((o) =>
      o.rdvNonHonoreMonths.reduce((a, b) => a + b)
    );
    var allAMenerTotals = datas.map((o) =>
      o.rdvAMenerMonths.reduce((a, b) => a + b)
    );

    setTotalHonore(allHonoreTotals.reduce((a, b) => a + b));
    setTotalNonHonore(allNonHonoreTotals.reduce((a, b) => a + b));
    setTotalAMener(allAMenerTotals.reduce((a, b) => a + b));
    // setTotal(allTotals.reduce((a, b) => a + b));
    //setTotalCommune(allTotals[0]);
    setTotalCommuneHonore(allHonoreTotals[0]);
    setTotalCommuneNonHonore(allNonHonoreTotals[0]);
    setTotalCommuneAMener(allAMenerTotals[0]);

    setChartState({
      line1: {
        series: rdvHonoreZoneDatas.map((o) => ({
          name: o.name,
          data: o.months,
        })),
        options: lineOptions,
      },
      pie1: {
        series: rdvHonoreZoneDatas.map((o) => o.months.reduce((a, b) => a + b)),
        options: {
          labels: rdvHonoreZoneDatas.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      line2: {
        series: rdvNonHonoreZoneDatas.map((o) => ({
          name: o.name,
          data: o.months,
        })),
        options: lineOptions,
      },
      pie2: {
        series: rdvNonHonoreZoneDatas.map((o) =>
          o.months.reduce((a, b) => a + b)
        ),
        options: {
          labels: rdvNonHonoreZoneDatas.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      line3: {
        series: rdvAMenerZoneDatas.map((o) => ({
          name: o.name,
          data: o.months,
        })),
        options: lineOptions,
      },
      pie3: {
        series: rdvAMenerZoneDatas.map((o) => o.months.reduce((a, b) => a + b)),
        options: {
          labels: rdvAMenerZoneDatas.map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      //PAR COMMUNE
      line4: {
        series: [datas[0]].map((o) => ({
          name: o.name,
          data: o.rdvHonoreMonths,
        })),
        options: lineOptions,
      },
      pie4: {
        series: [datas[0]].map((o) =>
          o.rdvHonoreMonths.reduce((a, b) => a + b)
        ),
        options: {
          labels: [datas[0]].map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      line5: {
        series: [datas[0]].map((o) => ({
          name: o.name,
          data: o.rdvNonHonoreMonths,
        })),
        options: lineOptions,
      },
      pie5: {
        series: [datas[0]].map((o) =>
          o.rdvNonHonoreMonths.reduce((a, b) => a + b)
        ),
        options: {
          labels: [datas[0]].map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
      line6: {
        series: [datas[0]].map((o) => ({
          name: o.name,
          data: o.rdvAMenerMonths,
        })),
        options: lineOptions,
      },
      pie6: {
        series: [datas[0]].map((o) =>
          o.rdvAMenerMonths.reduce((a, b) => a + b)
        ),
        options: {
          labels: [datas[0]].map((o) => o.name),
          legend: { position: "bottom" },
          chart: {
            toolbar: {
              show: true,
            },
          },
        },
      },
    });
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    totalHonore,
    totalNonHonore,
    totalAMener,
    totalCommuneHonore,
    totalCommuneNonHonore,
    totalCommuneAMener,
    allDatas,
    selectedCommune,
    onClickCommune,
  };
};

export default useNbRdvEGCafByTerritory;
