import React, { useState, useEffect } from "react";
import DigitControl from "./DigitControl";
import { DateTime } from "luxon";

const Control = ({
  inputStyle = {},
  label = false,
  type,
  value,
  change,
  name,
  id = null,
  suffix = false,
  k = -1,
  error = false,
  disabled = false,
  checked = false,
  datas = false,
  dataIndex = "id",
  dataLabel = "name",
  dataLabel2 = false,
  dataLabel3 = false,
  className = "",
  itemClassName = "",
  required = false,
  inputClassName = "",
  dataLabelIndex = false,
  inputParams = { min: 1, max: 100, step: 1 },
  selectFirstLabel = "...",
  containerStyle = {},
  isValid = false,
  autocomplete = "on",
  margin = " my-0 ",
  placeholder = "",
  digitParams = [],
  btnInline = false,
  dataIcon = false,
  dataLabelDefault = "",
  btnSm = false,
  labelStyle = {},
  inputContainerStyle = {},
  autoCompleteDisplayIndex = false,
  noSelectFirstOption = false,
  groups = [],
}) => {
  const [acInput, setAcInput] = useState(value);
  const [acResults, setAcResults] = useState([]);
  const [isOpen, setIsOpen] = useState({});

  const toggleAccordion = (groupId) => {
    setIsOpen((i) => (i == groupId ? null : groupId));
  };

  useEffect(() => {
    if (type == "autocomplete") {
      var acs = [];
      datas.forEach((d) => {
        if (
          (value &&
            d[dataLabel] &&
            d[dataLabel].toLowerCase().includes(value.toLowerCase())) ||
          (d[dataLabel2] &&
            d[dataLabel2].toLowerCase().includes(value.toLowerCase())) ||
          (d[dataLabel3] &&
            d[dataLabel3].toLowerCase().includes(value.toLowerCase()))
        ) {
          acs.push(d);
        }
      });

      setAcResults(acs);
    }
  }, [value]);

  useEffect(() => {
    if (type == "autocomplete") {
      var selector = k != -1 ? name + "-" + k : id ? id : name;
      document
        .querySelector("#" + selector)
        .addEventListener("focus", function () {
          document.querySelector("#acres" + selector).style.display = "block";
        });
      document
        .querySelector("#" + selector)
        .addEventListener("focusout", function () {
          setTimeout(() => {
            document.querySelector("#acres" + selector).style.display = "none";
          }, 200);
        });
    }
  }, []);

  if (type == "checkbox") {
    return (
      <div
        style={containerStyle}
        className={
          "animated fadeIn custom-control custom-switch " +
          className +
          " " +
          margin
        }
      >
        <input
          type="checkbox"
          className={"custom-control-input " + inputClassName}
          disabled={disabled ? "disabled" : false}
          name={name}
          id={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
          onChange={k != -1 ? (e) => change(e, k) : change}
          checked={checked ? "checked" : false}
        />
        <label
          className="custom-control-label"
          style={labelStyle}
          htmlFor={
            k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name
          }
        >
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
      </div>
    );
  } else if (type == "select") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <select
            className={"form-control " + inputClassName}
            disabled={disabled ? "disabled" : false}
            value={value ? value : ""}
            name={name}
            id={k != -1 ? name + "-" + k : id ? id : name}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          >
            {!noSelectFirstOption && (
              <option value="">{selectFirstLabel}</option>
            )}
            {datas.map((data, k) => (
              <option
                disabled={data.isDisabled}
                key={name + "-" + data[dataIndex] + (data.id ? data.id : k)}
                value={data[dataIndex]}
              >
                {dataLabelIndex
                  ? data[dataLabel][dataLabelIndex]
                  : data[dataLabel]}{" "}
                {dataLabel2 ? " - " + data[dataLabel2] : ""}
              </option>
            ))}
          </select>
          {suffix && <div className="input-group-append border">{suffix}</div>}
        </div>
        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "autocomplete") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            className={"form-control " + inputClassName}
            disabled={disabled ? "disabled" : false}
            value={
              autoCompleteDisplayIndex &&
              datas.find((d) => d[dataIndex] == value)
                ? datas.find((d) => d[dataIndex] == value)[
                    autoCompleteDisplayIndex
                  ]
                : value
                ? value
                : ""
            }
            name={name}
            id={k != -1 ? name + "-" + k : id ? id : name}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
            autoComplete={autocomplete}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 0, bottom: 17 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          <div
            className="ac-results  position-relative"
            id={"acres" + (k != -1 ? name + "-" + k : id ? id : name)}
            style={{
              top: -5,
              padding: 5,
              width: "100%",
              zIndex: "999",
              display: "none",
            }}
          >
            <p className="my-1 text-primary">
              Sélectionnez une option ci-dessous
            </p>
            {acResults.map((a, ak) => {
              if (ak >= 5) return false;
              return (
                <div
                  className="animated fadeInUp faster"
                  style={{
                    padding: "8px 5px",
                    width: "100%",
                    cursor: "pointer",
                    border: "1px solid #dddddd",
                    animationDelay: ak * 50 + "ms",
                  }}
                  onClick={
                    k != -1
                      ? () =>
                          change(
                            { target: { name: name, value: a[dataIndex] } },
                            k
                          )
                      : () =>
                          change({
                            target: { name: name, value: a[dataIndex] },
                          })
                  }
                  key={"acres" + ak + "" + k}
                >
                  {a[dataLabel]} {dataLabel2 ? " - " + a[dataLabel2] : ""}
                </div>
              );
            })}
          </div>
          {suffix && <div className="input-group-append border">{suffix}</div>}
        </div>
        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "textarea") {
    return (
      <div
        style={containerStyle}
        className={"form-group  animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <textarea
            className={"form-control " + inputClassName}
            type={type}
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {suffix && (
            <div className="input-group-append border">
              <span
                className="input-group-text"
                style={disabled ? { color: "#9a9a9a" } : {}}
              >
                {suffix}
              </span>
            </div>
          )}
        </div>
        {error && (
          <small
            className={
              "form-text text-danger " + (error[name] ? "animated flash" : "")
            }
          >
            {error[name]}
          </small>
        )}
      </div>
    );
  } else if (type == "btnList") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        <label
          htmlFor={k != -1 ? name + "-" + k : name}
          className="d-block"
          style={labelStyle}
        >
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
        <div
          style={inputContainerStyle}
          className={
            " " + (btnInline ? "d-flex flex-row flex-wrap " : "btn-group")
          }
        >
          {datas.map((data, dk) => (
            <button
              disabled={disabled ? "disabled" : false}
              className={
                itemClassName +
                " " +
                "btn m-1 btn-default " +
                (btnInline || btnSm ? "btn-sm " : " ") +
                (value == data[dataIndex] ? "active " : " ") +
                (btnInline ? "text-center btn-sm" : "")
              }
              style={{ minHeight: 0 }}
              key={
                name +
                "-" +
                (data.id ? data.id : data._id ? data._id + dk : "abcd" + dk)
              }
              onClick={
                k != -1
                  ? (e) =>
                      change(
                        { target: { name: name, value: data[dataIndex] } },
                        k
                      )
                  : (e) =>
                      change({ target: { name: name, value: data[dataIndex] } })
              }
            >
              {dataIcon && <i className={"fa fa-" + data[dataIcon]} />}
              <p style={{ width: !btnInline ? "93%" : "100%", margin: 0 }}>
                {dataLabelIndex
                  ? data[dataLabel][dataLabelIndex]
                  : data[dataLabel]}
              </p>
              {value == data[dataIndex] && !btnInline && (
                <i className="fa fa-check ml-2 pr-0 animated fadeInRight faster " />
              )}
            </button>
          ))}
        </div>

        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "checkboxList") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        <label htmlFor={k != -1 ? name + "-" + k : name} className="d-block">
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
        <div className="btn-group">
          {datas.map((data, k) => (
            <div
              className={"my-0 custom-control custom-switch"}
              key={`${name}${k}`}
            >
              <input
                type="checkbox"
                className={"custom-control-input " + inputClassName}
                disabled={disabled ? "disabled" : false}
                name={name}
                id={
                  k != -1
                    ? "customSwitch" + name + "-" + k
                    : "customSwitch" + name
                }
                onChange={
                  k != -1
                    ? (e) =>
                        change(
                          {
                            target: {
                              name: name,
                              value: data[dataIndex],
                              formArray: e,
                              isExclusive: data.isExclusive,
                            },
                          },
                          k
                        )
                    : (e) =>
                        change({
                          target: {
                            name: name,
                            value: data[dataIndex],
                            formArray: e,
                            isExclusive: data.isExclusive,
                          },
                        })
                }
                checked={value.includes(data[dataIndex]) ? "checked" : false}
              />
              <label
                className="custom-control-label"
                htmlFor={
                  k != -1
                    ? "customSwitch" + name + "-" + k
                    : "customSwitch" + name
                }
              >
                {dataLabelIndex
                  ? data[dataLabel][dataLabelIndex]
                  : data[dataLabel]}
                {required && <span className="text-danger ml-1">*</span>}
              </label>
            </div>
          ))}
        </div>

        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "checkBtnList") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        <label
          htmlFor={k != -1 ? name + "-" + k : name}
          className="d-block"
          style={labelStyle}
        >
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
        <div
          className={
            "btn-group mt-2 " + (btnInline ? "d-flex flex-row flex-wrap " : "")
          }
        >
          {datas.map((data, dk) => (
            <button
              style={inputStyle}
              disabled={disabled ? "disabled" : false}
              className={
                itemClassName +
                " " +
                "btn m-1 btn-default " +
                (btnSm ? " btn-sm " : " ") +
                (value.includes(data[dataIndex]) ? "active " : " ") +
                (btnInline ? "text-center btn-sm" : "")
              }
              key={
                name +
                "-" +
                (data.id ? data.id : data._id ? data._id + dk : "abcd" + dk)
              }
              onClick={
                k != -1
                  ? (e) =>
                      change(
                        {
                          target: {
                            name: name,
                            value: data[dataIndex],
                            formArray: e,
                            type: "checkBtnList",
                          },
                        },
                        k
                      )
                  : (e) =>
                      change({
                        target: {
                          name: name,
                          value: data[dataIndex],
                          formArray: e,

                          type: "checkBtnList",
                        },
                      })
              }
            >
              <p style={{ width: "93%", margin: 0 }}>
                {dataLabelIndex
                  ? data[dataLabel][dataLabelIndex]
                  : data[dataLabel]}
                {/* S'il la valeur data[dataLabel] n'est pas présente on affiche la valeur par défaut */}
                {!data[dataLabel] ? " " + data[dataLabelDefault] : ""}
                {dataLabel2 && " " + data[dataLabel2]}
              </p>
              {value.includes(data[dataIndex]) && !btnInline && (
                <i className="fa fa-check ml-2 pr-0 animated fadeInRight faster " />
              )}
            </button>
          ))}
        </div>

        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      </div>
    );
  } else if (type == "range") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          {/* <span>{inputParams.min}</span> */}
          <input
            min={inputParams.min}
            max={inputParams.max}
            step={inputParams.step}
            className={"form-control  custom-range " + inputClassName}
            type={type}
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {/* <span>{inputParams.max}</span> */}
          {suffix && (
            <div className="input-group-append border">
              <span
                className="input-group-text"
                style={disabled ? { color: "#9a9a9a" } : {}}
              >
                {suffix}
              </span>
            </div>
          )}
        </div>
        {error && (
          <small
            className={
              "form-text text-danger " + (error[name] ? "animated flash" : "")
            }
          >
            {error[name]}
          </small>
        )}
        <input
          type="text"
          className="form-control range-inp-value"
          onChange={k != -1 ? (e) => change(e, k) : change}
          name={name}
          value={value}
          disabled={disabled ? "disabled" : false}
          placeholder={placeholder}
        />
      </div>
    );
  } else if (type == "digits") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <DigitControl
            params={digitParams}
            name={name}
            complete={k != -1 ? (e) => change(e, k) : change}
            value={value}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 0, bottom: 51 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
        </div>

        {error && (
          <small
            className={
              "form-text text-danger " + (error[name] ? "animated flash" : "")
            }
          >
            {error[name]}
          </small>
        )}
      </div>
    );
  } else if (type == "numberInt") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              className="btn btn-light shadow-none border"
              onClick={
                k != -1
                  ? () =>
                      change({ target: { name: name, value: value - 1 } }, k)
                  : () => change({ target: { name: name, value: value - 1 } })
              }
            >
              -
            </button>
          </div>
          <input
            autoComplete={autocomplete}
            style={inputStyle}
            className={"form-control text-center " + inputClassName}
            type={type}
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 0, bottom: 17 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          <div className="input-group-append" style={{ border: "none" }}>
            <button
              className="btn btn-light shadow-none border"
              onClick={
                k != -1
                  ? () =>
                      change({ target: { name: name, value: value + 1 } }, k)
                  : () => change({ target: { name: name, value: value + 1 } })
              }
            >
              +
            </button>
          </div>
        </div>
        {error && (
          <small
            className={
              "form-text text-danger " + (error[name] ? "animated flash" : "")
            }
          >
            {error[name]}
          </small>
        )}
      </div>
    );
  } else if (type == "daySelector") {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              style={{
                height: 36,
                padding: "0 8px",
                boxShadow: "none",
                border: "1px solid #dedede",
              }}
              className="btn btn-default"
              onClick={() =>
                change({
                  target: {
                    name: name,
                    value: DateTime.fromISO(value).minus({ days: 1 }).toISO(),
                  },
                })
              }
            >
              <i className="fa fa-chevron-left" />
            </button>
          </div>
          <input
            autoComplete={autocomplete}
            style={inputStyle}
            className={"form-control text-center " + inputClassName}
            type={"date"}
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 0, bottom: 17 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          <div className="input-group-append pr-0" style={{ border: "none" }}>
            <button
              style={{
                height: 36,
                padding: "0 8px",
                boxShadow: "none",
                border: "1px solid #dedede",
              }}
              className="btn btn-default"
              onClick={() =>
                change({
                  target: {
                    name: name,
                    value: DateTime.fromISO(value).plus({ days: 1 }).toISO(),
                  },
                })
              }
            >
              <i className="fa fa-chevron-right" />
            </button>
          </div>
          {suffix && <div className="input-group-append border">{suffix}</div>}
        </div>
        {error && (
          <small
            className={
              "form-text text-danger " + (error[name] ? "animated flash" : "")
            }
          >
            {error[name]}
          </small>
        )}
      </div>
    );
  } else if (type == "checkboxListGroup") {
    /*
    This input is a list of accordions. Each accordion (or group) has a list of btnList  (only one can be selected in each group)
    The definition of the groups are in the groups props, as an array of objects with groupId and groupName.
    The possible answers in the data array have a groupId attribute, that enable to know in which group they belong.
    */
    return (
      <div
        style={containerStyle}
        className={"form-group " + className + " " + margin}
      >
        <label htmlFor={k != -1 ? name + "-" + k : name} className="d-block">
          {label}
          {required && <span className="text-danger ml-1">*</span>}
        </label>
        <div id="accordion" className="btn-group">
          {groups.map((group, gk) => {
            const selectedAnswerInGroup = datas.find(
              (data) =>
                data.group == group.id && value.includes(data[dataIndex])
            );

            return (
              <div className="" key={group.id}>
                <div className="" id={"heading" + group.id}>
                  <h5 className="mb-0">
                    <button
                      className="btn btn-default btn-input d-flex flex-column justify-content-center align-items-start w-100"
                      data-toggle="collapse"
                      data-target={"#collapse" + group.id}
                      aria-expanded="true"
                      aria-controls={"collapse" + group.id}
                      type="button"
                      onClick={() => toggleAccordion(group.id)}
                      style={{ gap: 10 }}
                    >
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        {group.label}{" "}
                        <i
                          className={
                            isOpen == group.id
                              ? `fa fa-caret-up`
                              : `fa fa-caret-down`
                          }
                        />
                      </div>
                      {selectedAnswerInGroup?.label && (
                        <div
                          className={`d-flex flex-wrap align-items-center 
                        ${
                          selectedAnswerInGroup.isDefault
                            ? "text-black-50"
                            : "text-primary"
                        }
                        `}
                        >
                          {/* <i
                            style={{ fontSize: 10 }}
                            className="fas fa-caret-right mr-2 text-primary"
                          /> */}
                          <small
                            className=""
                            style={{ fontSize: 12, fontWeight: "500" }}
                          >
                            {selectedAnswerInGroup?.label || ""}
                          </small>
                        </div>
                      )}
                    </button>
                  </h5>
                </div>
                <div
                  id={"collapse" + group.id}
                  className="collapse"
                  aria-labelledby={"heading" + group.id}
                  data-parent="#accordion"
                >
                  <div className="card-body pt-2">
                    {datas.map((data, dk) => {
                      if (data.group !== group.id) return null;
                      return (
                        <div className={"my-3 custom-control custom-switch"}>
                          <input
                            type="checkbox"
                            className={"custom-control-input " + inputClassName}
                            disabled={disabled ? "disabled" : false}
                            name={name}
                            id={
                              k != -1
                                ? "customSwitch" +
                                  data.group +
                                  dk +
                                  name +
                                  "-" +
                                  k
                                : "customSwitch" + data.group + dk + name
                            }
                            onChange={
                              k != -1
                                ? (e) =>
                                    change(
                                      {
                                        target: {
                                          name: name,
                                          value: data[dataIndex],
                                          formArray: e,
                                          isExclusive: data.isExclusive,
                                        },
                                      },
                                      k
                                    )
                                : (e) =>
                                    change({
                                      target: {
                                        name: name,
                                        value: data[dataIndex],
                                        formArray: e,
                                        isExclusive: data.isExclusive,
                                      },
                                    })
                            }
                            checked={
                              value.includes(data[dataIndex])
                                ? "checked"
                                : false
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={
                              k != -1
                                ? "customSwitch" +
                                  data.group +
                                  dk +
                                  name +
                                  "-" +
                                  k
                                : "customSwitch" + data.group + dk + name
                            }
                          >
                            {dataLabelIndex
                              ? data[dataLabel][dataLabelIndex]
                              : data[dataLabel]}
                            {required && (
                              <span className="text-danger ml-1">*</span>
                            )}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      </div>
    );
  } else {
    return (
      <div
        style={containerStyle}
        className={"form-group animated fadeIn  " + className + " " + margin}
      >
        {label && (
          <label htmlFor={k != -1 ? name + "-" + k : name} style={labelStyle}>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </label>
        )}
        <div className="input-group">
          <input
            autoComplete={autocomplete}
            style={inputStyle}
            className={"form-control " + inputClassName}
            type={type}
            disabled={disabled ? "disabled" : false}
            id={k != -1 ? name + "-" + k : id ? id : name}
            name={name}
            value={value}
            onChange={k != -1 ? (e) => change(e, k) : change}
            placeholder={placeholder}
          />
          {isValid && (
            <i
              style={{ position: "absolute", right: 6, bottom: 10 }}
              className="fa fa-check text-success animated fadeInLeft faster"
            />
          )}
          {suffix && <div className="input-group-append border">{suffix}</div>}
        </div>
        {error && (
          <small
            className={
              "form-text text-danger " + (error[name] ? "animated flash" : "")
            }
          >
            {error[name]}
          </small>
        )}
      </div>
    );
  }
};

export default Control;

/*
EXEMPLE APPEL AUTOCOMPLETE
  <Control
    className="animated fadeInUp faster"
    containerStyle={{ animationDelay: "100ms" }}
    label="Forme juridique"
    name="legalForm"
    value={company.legalForm}
    type="autocomplete"
    datas={legalForms}
    dataIndex={"code"}
    dataLabel={"code"}
    dataLabel2={"label"}
    error={errors}
    change={updateData}
    placeholder={"SARL, EURL, SAS..."}
    isValid={legalForms.find(l => l.code == company.legalForm)}
  />

EXEMPLE APPEL DIGITS
  <Control
    type="digits"
    className="animated fadeInUp faster"
    containerStyle={{ animationDelay: "50ms" }}
    label="SIRET du siège social"
    digitParams={[
      { size: 9, value: "", placeholder: "SIREN" },
      { size: 5, value: "", placeholder: "clé" }
    ]}
    name={"siret"}
    change={updateData}
    value={company.siret}
    isValid={company.siret.length == 14}
    error={errors}
  />
*/
