import React, { useEffect } from "react";
import tools from "../../../../../../../helpers/tools";
import Control from "../../../../../../common/Control";
import DynamicList from "../../../../../../common/DynamicList";
import useStoreState from "../../../Context/Store/useStoreState";
import OldEpDetails from "./OldEpDetails";

const DecisionSanctionForm = ({ avisEp, setAvisEp, recipient, isClosed }) => {
  const { state, gState, items, items_actions } = useStoreState();
  const isDirector =
    gState.auth.user.role == "CAF_DIRECTOR" || gState.auth.user.isDirector;
  var isLocked = (!isDirector && isClosed) || items.ep.endedAt;

  const { errors } = state;
  const {
    EP_SANCTION_REDUCTIONS,
    EP_SANCTION_DURATIONS,
    EP_REDUCTION_LEVELS,
    EP_SANCTION_PRESENCES,
  } = gState.constants.items;

  useEffect(() => {
    setAvisEp({
      ...avisEp,
      sanction: { ...avisEp.sanction, isRightsMaintained: true },
    });
  }, [avisEp.sanction.level]);

  useEffect(() => {
    if (!avisEp.recipientPresence && recipient.presence?.answer) {
      setAvisEp({ ...avisEp, recipientPresence: recipient.presence?.answer });
    }
  }, []);

  return (
    <div className="position-relative">
      {isLocked ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0,0,0,0)",
            width: "100%",
            height: "100%",
            zIndex: "999",
          }}
        ></div>
      ) : null}
      <Control
        label="L'allocataire est-il présent ?"
        type="btnList"
        btnInline
        name="recipientPresence"
        disabled={isLocked ? true : false}
        datas={EP_SANCTION_PRESENCES}
        value={avisEp.recipientPresence}
        change={(e) =>
          setAvisEp({ ...avisEp, recipientPresence: e.target.value })
        }
        error={errors.EPNotice || {}}
      />

      <OldEpDetails oldEpDetails={recipient.oldEpDetails} />

      <Control
        label="Niveau de la sanction"
        type="btnList"
        disabled={isLocked ? true : false}
        btnInline
        name="sanctionLevel"
        datas={EP_REDUCTION_LEVELS}
        value={avisEp.sanction.level}
        change={(e) =>
          setAvisEp({
            ...avisEp,
            sanction: {
              ...avisEp.sanction,
              level: e.target.value,
              sanctionType: [],
            },
          })
        }
        error={errors.EPNotice?.sanction || {}}
      />
      {avisEp.sanction.level && avisEp.sanction.level != 3 && (
        <DynamicList
          listLabel="Forme de la sanction"
          btnLabel="Ajouter une période"
          uniqueKey="pf-1"
          name="sanctionType"
          disabled={isLocked ? true : false}
          fields={[
            {
              label: "Durée",
              name: "duration",
              type: "btnList",
              datas: EP_SANCTION_DURATIONS.filter((dur) => {
                if (avisEp.sanction.level == 1) {
                  return dur.id != 4;
                } else {
                  return true;
                }
              }),
              btnInline: true,
            },
            {
              label: "Réduction",
              name: "reduction",
              type: "btnList",
              datas: EP_SANCTION_REDUCTIONS.filter((red) => {
                if (avisEp.sanction.level == 1) {
                  return red.id != 4;
                }
                return true;
              }),
              btnInline: true,
            },
          ]}
          items={avisEp.sanction.sanctionType}
          change={(e) =>
            setAvisEp({
              ...avisEp,
              sanction: { ...avisEp.sanction, sanctionType: e.target.value },
            })
          }
          error={errors.EPNotice?.sanction || {}}
        />
      )}
      {avisEp.sanction.level == 2 && (
        <Control
          disabled={isLocked ? true : false}
          label="Maintien des droits RSA ?"
          type="btnList"
          btnInline
          name="isRightsMaintained"
          datas={[
            { id: true, name: "Oui" },
            { id: false, name: "Non (radiation)" },
          ]}
          value={avisEp.sanction.isRightsMaintained}
          change={(e) =>
            setAvisEp({
              ...avisEp,
              sanction: {
                ...avisEp.sanction,
                isRightsMaintained: e.target.value,
              },
            })
          }
          error={errors}
        />
      )}
      <Control
        disabled={isClosed ? true : false}
        label="Note interne"
        type="textarea"
        name="note"
        value={avisEp.note}
        change={(e) => {
          setAvisEp({
            ...avisEp,

            note: e.target.value,
          });
        }}
      />
    </div>
  );
};

export default DecisionSanctionForm;
