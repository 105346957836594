import { useState } from "react";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";

const anomaliesAtom = atom([]);
export const searchAtom = atom({
  recipientName: "",
  recipientEmail: "",
  recipientNir: "",
  recipientCAFId: "",
  onlyWithoutRDSP: false,
  onlyWithoutRdvEG: false,
  debutDateEnvoiNotif1: "",
  finDateEnvoiNotif1: "",
  rsaType: "",
  recipientType: "",
});
export const differedSearchAtom = atom({});

const filteredAnomaliesAtom = atom((get) => {
  const anomalies = get(anomaliesAtom);
  const search = get(differedSearchAtom);

  let filtered = [...anomalies].filter((a) => {
    let shouldKeep = true;
    if (search.recipientName) {
      shouldKeep =
        shouldKeep &&
        (a.firstname
          .toLowerCase()
          .includes(search.recipientName.toLowerCase()) ||
          a.lastname
            .toLowerCase()
            .includes(search.recipientName.toLowerCase()));
    }
    if (search.recipientEmail) {
      shouldKeep =
        shouldKeep &&
        a.email.toLowerCase().includes(search.recipientEmail.toLowerCase());
    }
    if (search.recipientNir) {
      shouldKeep =
        shouldKeep &&
        a.nir.toLowerCase().includes(search.recipientNir.toLowerCase());
    }
    if (search.recipientCAFId) {
      shouldKeep =
        shouldKeep && a.CAFId?.toString().includes(search.recipientCAFId);
    }
    if (search.rsaType) {
      shouldKeep = shouldKeep && a.typeDroits == search.rsaType;
    }
    if (search.onlyWithoutRDSP) {
      shouldKeep = shouldKeep && a.typeAnomalie == "BRSA_SDD_WITHOUT_RDSP";
    }
    if (search.onlyWithoutRdvEG) {
      shouldKeep = shouldKeep && a.typeAnomalie == "BRSA_SDD_WITHOUT_RDV_EG";
    }
    if (search.debutDateEnvoiNotif1) {
      shouldKeep =
        shouldKeep &&
        DateTime.fromISO(a.dateEnvoiNotif1) >=
          DateTime.fromISO(search.debutDateEnvoiNotif1).startOf("day");
    }
    if (search.finDateEnvoiNotif1) {
      shouldKeep =
        shouldKeep &&
        DateTime.fromISO(a.dateEnvoiNotif1) <=
          DateTime.fromISO(search.finDateEnvoiNotif1).endOf("day");
    }
    if (search.recipientType) {
      shouldKeep = shouldKeep && a.model == search.recipientType;
    }
    return shouldKeep;
  });

  return sortResults(filtered);
});

const paginatedAnomaliesAtom = atom((get) => {
  const filteredAnomalies = get(filteredAnomaliesAtom);
  const pagination = get(paginationAtom);
  return filteredAnomalies.slice(
    (pagination.page - 1) * pagination.nbItemsByPage,
    pagination.page * pagination.nbItemsByPage
  );
});

const nbPagesAtom = atom((get) => {
  const filteredAnomalies = get(filteredAnomaliesAtom);
  const pagination = get(paginationAtom);
  return Math.ceil(filteredAnomalies.length / pagination.nbItemsByPage);
});

export const paginationAtom = atom({
  page: 1,
  nbItemsByPage: 25,
});

const sortResults = (datas) => {
  return datas.sort((a, b) =>
    !a.dateEnvoiNotif1 || !b.dateEnvoiNotif1
      ? 1
      : DateTime.fromISO(a.dateEnvoiNotif1) >
        DateTime.fromISO(b.dateEnvoiNotif1)
      ? 1
      : -1
  );
};

function useSuiviAnomalyList() {
  const [state] = useStore();
  const constants = state.constants.items;
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [anomalies, setAnomalies] = useAtom(anomaliesAtom);
  const [nbResults, setNbResults] = useState(0);

  const [filteredAnomalies] = useAtom(filteredAnomaliesAtom);
  const [paginatedAnomalies] = useAtom(paginatedAnomaliesAtom);
  const [pagination, setPagination] = useAtom(paginationAtom);
  const [nbPages] = useAtom(nbPagesAtom);

  const actions = {
    searchData: () => {
      setErrors({});
      setIsLoading(true);
      setNbResults((r) => 0);

      dataService.post(
        "anomalies/brsa-sdd-sans-rdsp-ou-sans-rdv-eg",
        {},
        (datas) => {
          setAnomalies(sortResults(datas));
          setNbResults((r) => datas.length);
          setIsLoading(false);
        },
        (errors) => {
          setErrors(errors);
          setAnomalies([]);
        },
        () => setIsLoading(false)
      );
    },

    changePage: (page) => {
      setPagination((p) => ({ ...p, page }));
    },
    changeNbItemsByPage: (nbItemsByPage) => {
      setPagination((p) => ({ ...p, nbItemsByPage }));
    },
  };

  return {
    constants,
    isLoading,
    nbResults,
    errors,
    actions,
    filteredAnomalies,
    paginatedAnomalies,
    nbPages,
    pagination,
  };
}

export default useSuiviAnomalyList;
