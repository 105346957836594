import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { modal2Actions } from "../../../../context/actions/modal2Actions";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Accordion from "../../../common/Accordion";
import Control from "../../../common/Control";
import ExportCSV from "../../../common/ExportCsv";
import Loader from "../../../common/Loader";
import Table from "../../../common/Table";
import useOrganizations from "../../../Hooks/useOrganizations/useOrganizations";
import ModifyMotifs from "./ModifyMotifs";
import ModifyMotifsReo from "./ModifyMotifsReo";

const EpRecipients = ({ ep }) => {
  const [state, dispatch] = useStore();
  const mActions = modal2Actions(state, dispatch);
  const constants = state.constants.items;
  const { EP_TYPES, MARITAL_STATUS, EP_SANCTION_PRESENCES, EP_PARAMS } =
    constants;
  const [recipients, setRecipients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { organizations: orgas, onGetOrganizations } = useOrganizations();
  const amISuperAdmin = state.auth.user.role === "SUPER_ADMIN";

  useEffect(() => {
    getEpRecipients();
    onGetOrganizations();
  }, []);

  const getEpRecipients = () => {
    setIsLoading(true);
    dataService.get(
      `eps/${ep.id}/recipients`,
      (epRecipients) => {
        setRecipients(epRecipients);
      },
      (err) => {},
      () => setIsLoading(false)
    );
  };

  const modifyMotifs = (elem) => {
    mActions.updateModal({
      isOpen: true,
      content: ["SANCTION", "SOCIAL_PATH_MAINTAIN"].includes(elem.type) ? (
        <ModifyMotifs epDatas={elem} getEpRecipients={getEpRecipients} />
      ) : (
        <ModifyMotifsReo epDatas={elem} getEpRecipients={getEpRecipients} />
      ),
    });
  };

  const modifyRecipientPresence = (recipient, e) => {
    if (
      !window.confirm(
        "Êtes vous certain(e) de vouloir modifier la présence de l'allocataire à cet EP ?"
      )
    ) {
      return false;
    }
    const { name, value } = e.target;
    setRecipients(
      recipients.map((rec) =>
        rec.id == recipient.id
          ? {
              ...rec,
              presence: {
                ...rec.presence,
                [name]: value,
              },
            }
          : rec
      )
    );

    saveRecipientPresence({
      ...recipient,
      presence: {
        ...recipient.presence,
        [name]: value,
      },
    });
  };

  const saveRecipientPresence = (recipient) => {
    dataService.patch(
      `epdetails/${recipient.id}/presence`,
      { answer: recipient.presence.answer },
      (datas) => {
        toast.success("La présence de l'allocataire a bien été enregistrée");
      }
    );
  };

  //epdetails/:epDetailId/
  const removeRecipientFromEP = (epDetailId) => {
    dataService.remove(`epdetails/${epDetailId}`, {}, (datas) => {
      toast.success("L'allocataire a bien été retiré de cet EP");
      getEpRecipients();
    });
  };

  const removeRecipientFromEPAsSuperAdmin = (epDetailId) => {
    dataService.remove(`epdetails/${epDetailId}/force`, {}, (datas) => {
      toast.success("L'allocataire a bien été retiré de cet EP");
      getEpRecipients();
    });
  };

  const getOtherCols = (insType) => {
    if (insType != "SANCTION") return [];
    return [
      {
        path: "*",
        name: "Présence alloc.",
        render: (el) =>
          el.presence ? (
            <div>
              <Control
                type="btnList"
                name="answer"
                datas={EP_SANCTION_PRESENCES}
                value={el.presence.answer}
                change={(e) => modifyRecipientPresence(el, e)}
              />
              <div className="d-flex flex-column text-black-50">
                {/* <small>
                  invité le{" "}
                  {DateTime.fromISO(el.presence.confirmSentAt).toFormat(
                    "dd/MM/yyyy"
                  )}
                </small> */}

                {el.presence.confirmAnswerUpdatedAt && (
                  <small>
                    répondu le{" "}
                    {DateTime.fromISO(
                      el.presence.confirmAnswerUpdatedAt
                    ).toFormat("dd/MM/yyyy")}
                  </small>
                )}
                {el.presence.confirmByManager && (
                  <small>
                    modifié par{" "}
                    {tools.getFullname(el.presence.confirmByManager)}
                  </small>
                )}
              </div>
            </div>
          ) : (
            ""
          ),
      },
    ];
  };

  const conditionnalColumn = (eptype) => {
    if (eptype.id == "REORIENTATION_OK" || eptype.id == "REORIENTATION_KO") {
      return {
        name: "Part. prenant",
        path: "EPNotice",
        render: (el) => {
          var orga = orgas.find(
            (o) => o.id == el?.reorientation?.orientation?.toPartner
          );
          return orga?.name || "";
        },
      };
    } else {
      return {};
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <h4 className="mb-3" style={{ fontSize: 16 }}>
          Liste des allocataires inscrits à l'EP{" "}
          {isLoading ? (
            <Loader />
          ) : (
            <ExportCSV
              datas={recipients.map((r) => ({
                ...r,
                type: tools.findIn(EP_TYPES, "id", r.type).name,
                fullname: tools.getFullname(r.userRecipient),
                NIR: r.userRecipient.nir,
                orientation: r.userRecipient.orientation?.partner,
                toPartner: tools.findIn(
                  orgas,
                  "id",
                  r.EPNotice?.reorientation?.orientation?.toPartner
                ).name,
                CAFId: r.userRecipient.CAFId,
                address: tools.getFullAddress(r.userRecipient.location),
                birthdate: tools.formatDate(r.userRecipient.birthdate),
                maritalStatus: tools.findIn(
                  MARITAL_STATUS,
                  "id",
                  r.userRecipient.maritalStatus
                ).name,
              }))}
              exportOptions={{
                excludedFields: [],
              }}
              fields={[
                { name: "Type", path: "type" },
                { name: "Allocataire", path: "fullname" },
                { name: "NIR", path: "NIR" },
                { name: "Orientation", path: "orientation" },
                { name: "N°Alloc", path: "CAFId" },
                { name: "Adresse", path: "address" },
                { name: "Date naiss", path: "birthdate" },
                { name: "Sit fam", path: "maritalStatus" },
                { name: "Orga prenant", path: "toPartner" },
              ]}
            />
          )}
        </h4>
        <Accordion
          items={EP_TYPES.map((eptype) => {
            var recipientsOfType = recipients.filter(
              (rec) => rec.type == eptype.id
            );
            return {
              title: (
                <div>
                  <strong>
                    {eptype.name}
                    <span
                      className={`badge badge-${
                        recipientsOfType.length > 0 ? "success" : "dark"
                      } ml-2`}
                    >
                      {recipientsOfType.length}
                    </span>
                  </strong>
                </div>
              ),
              content: (
                <Table
                  fullWidth
                  fields={[
                    {
                      name: amISuperAdmin ? "retirer de l'EP" : "",
                      path: "*",
                      render: (el) => {
                        return amISuperAdmin ? (
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              removeRecipientFromEPAsSuperAdmin(el.id)
                            }
                          >
                            <i className="fa fa-times" />
                          </button>
                        ) : null;
                      },
                    },
                    {
                      name: "Allocataire",
                      path: "*",
                      render: (el) => {
                        var sendArDate = DateTime.fromISO(ep.beginAt).minus({
                          days: EP_PARAMS.find((epp) => epp.id == "SEND_AR")
                            .value,
                        });

                        //on bloque le retrait de l'alloc à l'ep si la date AR - SEND_AR est dépassée
                        var removeAvailable =
                          sendArDate >= DateTime.local() &&
                          eptype.id != "REORIENTATION_OK" &&
                          eptype.id != "REORIENTATION_KO";
                        return (
                          <>
                            <div>{tools.getFullname(el.userRecipient)}</div>
                            <div>
                              <small>{el.userRecipient.nir}</small>
                            </div>
                            {removeAvailable && (
                              <button
                                className="btn btn-danger text-left btn-sm"
                                onClick={() => removeRecipientFromEP(el.id)}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                retirer de l'EP
                              </button>
                            )}
                          </>
                        );
                      },
                    },
                    {
                      name: "Orientation",
                      path: "userRecipient",
                      render: (el) => el.orientation?.partner,
                    },
                    { ...conditionnalColumn(eptype) },
                    {
                      name: "N°Alloc.",
                      path: "userRecipient",
                      render: (el) => el.CAFId,
                    },
                    {
                      name: "Adresse",
                      path: "userRecipient",
                      render: (el) => tools.getFullAddress(el.location),
                    },
                    {
                      name: "Date naiss.",
                      path: "userRecipient",
                      render: (el) =>
                        el.birthdate
                          ? DateTime.fromISO(el.birthdate).toFormat(
                              "dd/MM/yyyy"
                            )
                          : null,
                    },
                    {
                      name: "Sit. Fam.",
                      path: "userRecipient",
                      render: (el) =>
                        el.maritalStatus
                          ? MARITAL_STATUS.find(
                              (ms) => ms.id == el.maritalStatus
                            ).name
                          : null,
                    },
                    {
                      name: "Nb pers. à charge",
                      path: "userRecipient",
                      render: (el) => el.nbChildrenOrDependant,
                    },
                    {
                      name: "Motifs inscr. EP",
                      path: "*",
                      render: (el) => (
                        <button
                          className="btn btn-link"
                          onClick={() => modifyMotifs(el)}
                        >
                          Motifs
                        </button>
                      ),
                    },
                    {
                      name: "Fiche alloc.",
                      path: "*",
                      render: (el) => {
                        var route = tools.getRecipientLinkFromParams(
                          el.userRecipientRef,
                          el.userRecipient.id
                        );
                        return (
                          <a
                            className="btn btn-link"
                            target="_BLANK"
                            href={route}
                          >
                            Détail
                          </a>
                        );
                      },
                    },
                    ...getOtherCols(eptype.id),
                  ]}
                  datas={recipientsOfType}
                />
              ),
            };
          })}
        />
      </div>
    </div>
  );
};

export default EpRecipients;
