import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import "./sidenav.scss";
import colors from "../../../helpers/colors";

const Header = withRouter((props) => {
  const [state, dispatch] = useStore();
  const [sideNavOpen, setSivenavOpen] = useState(true);

  const [showSub, setShowSub] = useState(false);
  const { user } = state.auth;
  // useEffect(() => {
  //   window.$("#sidebar").addClass("active");
  // }, [props.location.pathname]);

  const cantViewEP =
    ["MANAGER_PARTNER", "AGENT_PARTNER"].includes(user.role) && !user.isEP;

  useEffect(() => {
    let subMenus = document.getElementsByClassName("submenu");
    if (subMenus) {
      for (var y = 0; y < subMenus.length; y++) {
        let parentElem = subMenus[y];
        let childs = parentElem.childNodes;
        let disabledCount = 0;
        for (var i = 0; i < childs.length; i++) {
          if (window.getComputedStyle(childs[i]).display === "none")
            disabledCount++;
        }
        if (disabledCount == childs.length) {
          let elementToHideId = parentElem.getAttribute("id");
          let linkToHide = document.querySelector(`#${elementToHideId}-link`);
          linkToHide.classList.remove("d-flex");
          linkToHide.classList.add("d-none");
        }
      }
    }
  });

  var authData = state.auth;
  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <>
      <nav
        id="sidebar"
        className={"noprint " + (sideNavOpen ? "active" : "")}
        onMouseEnter={() => setSivenavOpen(false)}
        onMouseLeave={() => setSivenavOpen(true)}
        style={
          process.env.REACT_APP_NODE_ENV !== "production"
            ? {
                background: colors.dark,
              }
            : {}
        }
      >
        <button
          type="button"
          id="sidebarCollapse"
          className={`navbar-btn noprint ${sideNavOpen ? "active" : ""}`}
          onClick={() => {
            setSivenavOpen((s) => !s);
          }}
          style={{
            opacity: authData.isAuthenticated ? "1" : "0",
          }}
        >
          <i className="fa fa-chevron-left" />
        </button>
        <div
          className="sidebar-header text-center"
          style={
            process.env.REACT_APP_NODE_ENV !== "production"
              ? {
                  background: colors.dark,
                }
              : {}
          }
        >
          <div className="animated pulse slow d-flex align-items-center justify-content-center flex-column">
            <Link
              to="/"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              <img
                src="/assets/logo-espoar-new.png"
                className=""
                style={{
                  width: "85%",
                  opacity: sideNavOpen ? "0" : "1",
                }}
              />
            </Link>
          </div>
        </div>

        <ul className="list-unstyled mt-3 components position-relative">
          {props.sidenavItems.map((m, mk) => (
            <li key={"menuitemside" + mk}>
              {m.path !== "#" ? (
                <NavLink
                  className={`nav-link d-flex align-items-center`}
                  to={m.path}
                  data-priv={m.dataPriv ? m.dataPriv : ""}
                >
                  <span
                    className="d-center"
                    style={{
                      position: "absolute",
                    }}
                  >
                    <i className={`mr-3 fa fa-${m.icon}`} />

                    <span>{m.name}</span>
                  </span>
                </NavLink>
              ) : (
                <a
                  href="#"
                  style={{ color: "#b3b3b3" }}
                  className={`nav-link d-flex align-items-center `}
                  id={`submenu-${mk}-link`}
                  onClick={() =>
                    showSub == mk ? setShowSub(false) : setShowSub(mk)
                  }
                >
                  <span
                    className="d-center"
                    style={{
                      position: "absolute",
                    }}
                  >
                    <span>{m.name}</span>
                    <i className="ml-3 fa fa-chevron-down" />
                  </span>
                </a>
              )}
              {m.items && (
                <ul
                  className={`list-unstyled components position-relative submenu`}
                  id={`submenu-${mk}`}
                  style={{
                    borderLeft: "2px solid" + colors.primary,
                    display: showSub == mk ? "block" : "none",
                  }}
                >
                  {m.items.map((it, itk) => {
                    if (it.isEP && cantViewEP) {
                      return null;
                    }
                    return (
                      <li
                        key={"menuitemside" + mk + itk}
                        data-priv={it.dataPriv ? it.dataPriv : ""}
                      >
                        <NavLink
                          className="nav-link d-flex align-items-center"
                          to={it.path}
                        >
                          <span
                            className="d-center"
                            style={{
                              position: "absolute",
                            }}
                          >
                            <i className={`mr-3 fa fa-${it.icon}`} />

                            <span>{it.name}</span>
                          </span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
      {process.env.REACT_APP_NODE_ENV !== "production" && (
        <div
          className="position-fixed badge badge-info"
          style={{
            bottom: 10,
            right: 10,
            zIndex: "9999",
          }}
        >
          {process.env.REACT_APP_NODE_ENV}
        </div>
      )}
    </>
  ) : (
    <></>
  );
});

export default withRouter(Header);
