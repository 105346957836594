import { DateTime } from "luxon";
import React, { useEffect } from "react";
import tools from "../../../../../../../helpers/tools";
import useOrganizations from "../../../../../../Hooks/useOrganizations/useOrganizations";
import useStoreState from "../../../Context/Store/useStoreState";
import MotifDisplay from "./MotifDisplay";

const OldEpDetailsReo = ({ oldEpDetails }) => {
  const { state, gState, items, items_actions, spActions } = useStoreState();
  const { organizations, onGetOrganizations } = useOrganizations();

  useEffect(() => {
    onGetOrganizations();
  }, []);

  const { EP_TYPES, REORIENTATION_TYPES } = gState.constants.items;

  const openMotifs = (rec) => {
    spActions.updateSidePanel({
      isOpen: true,
      content: <MotifDisplay recipient={rec} />,
    });
  };

  const oldSanctions = oldEpDetails?.length
    ? oldEpDetails.filter((epd) => epd.type == "REORIENTATION_KO")
    : [];

  return oldSanctions.length ? (
    <div className="alert alert-warning my-2">
      <div>
        <strong>Des antécédants ont été trouvés</strong>
      </div>
      <ul>
        {oldEpDetails.map((old) => {
          if (!old.EPNotice.reorientation?.partner) return null;
          return (
            <li className="mb-2">
              <span className="mr-1 font-italic text-black-50">
                EP du{" "}
                {DateTime.fromISO(old.updatedAt)
                  .setLocale("FR")
                  .toFormat("dd LLLL yyyy")}{" "}
                -
              </span>
              <span className="mr-1">
                {EP_TYPES.find((type) => type.id == old.type).name}
              </span>

              <span className="mr-1">
                vers{" "}
                {
                  tools.findIn(
                    organizations,
                    "id",
                    old.EPNotice.reorientation.partner
                  ).name
                }
              </span>
              <span className="mr-1">
                <button
                  className="btn btn-outline-danger border-danger border btn-sm position-relative"
                  style={{ zIndex: "9999" }}
                  onClick={() => openMotifs(old)}
                >
                  Motifs
                </button>
              </span>
              <div>
                <span className="badge badge-light border">
                  {
                    tools.findIn(
                      REORIENTATION_TYPES,
                      old.EPNotice.reorientation.reorientationType
                    ).name
                  }
                </span>
                {/* {old.EPNotice.sanction.sanctionType.map((st) => (
                  <span className="badge badge-light border">
                    {EP_SANCTION_DURATIONS.find((dur) => dur.id == st.duration).name}{" "}
                    {EP_SANCTION_REDUCTIONS.find((red) => red.id == st.reduction).name}
                  </span>
                ))} */}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default OldEpDetailsReo;
